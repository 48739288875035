export const interviewQuestionformField = {
    headOfFamily: "", 
    age: "",
    gender: "",
    maritalStatus: "",
    education: "",
    isTheAnnualHouseholdIncomeSufficient: "", 
    apartFromFarmingDoYouHaveAnyOtherOccupation: "",
    doYouHaveAnyDebtLeft: "",
    whereDoYouGetLoansFrom: "",
    doesDebtOrAnyOtherCauseFamilyStrife: "",
    doYouFaceAnyKindOfMentalStressDueToYourChildrensMarriageOrTheirYoungAgeOrAnyOtherReasons: "",
    doYouOrAnyoneElseInTheHouseHasAnyKindOfAddiction: "",
    doesAnyoneInTheHouseHaveAMajorIllness: "",
    hasAnyoneCommittedSuicideAtHomeOrAmongRelatives: "",
    isAnyoneUnemployedAtYourHome: "",
    hasBarrennessNaturalDisastersOrOtherCausesCausedFinancialHardship: "",
    isTheCardOfAyushyamanBharatOrMahatmaJotibaPhuleJanArogyaYojanaissued: "",
    theSignatureOrThumbprintOfTheHeadOfTheFamily: "",
}

export const phq12FormFields = {
    feelingSadBlueOrDepressed: "",
    lossOfInterestOrPleasureInMostOfTheThings: "",
    feelingTiredOrLowOnEnergyMostOfTheTimes: "",
    lossOfAppetiteOrWeightLoss: "",
    overeatingOrWeightGain: "",
    troubleFallingAsleepOrStayingAsleep: "",
    sleepingTooMuch: "",
    moreTroubleThanUsualConcentratingOnThings: "",
    feelingDownOnYourselfNoGoodOrWorthless: "",
    beingFidgetyOrRestlessThatYouMoveAroundALotThanUsual: "",
    movedOrSpokeSoSlowlyThatOtherPeopleCouldHaveNoticed: "",
    thoughtAboutDeathMoreThanUsualEitherYourOwnOrSomeoneElseOrDeathInGeneral: "",
}



export const summaryQuestions = {
    classificationOfDepressedPersonByCalling104: "",
    call108: "",
    placeSentForReferenceService: "",
    notes: "",
    phaseOfSurvey: ""
}