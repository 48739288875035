import React from 'react';
import AdminNavbar from './layout/Navbar';
import Sidebar from "./layout/Sidebar";

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/paper-dashboard.min.css";
import "../../assets/demo/demo.css";

class AdminDashboard extends React.Component {
    render() {
        return (<>
            <div className="wrapper ">
                <Sidebar/>
                <div className="main-panel">
                    <AdminNavbar/>
                <div className="content">
                    
                </div>
                </div>
            </div>
        </>
        )
    }
}

export default AdminDashboard;