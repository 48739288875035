import React from "react";
import {
    Button,
    Card,
    Form,
    Row,
    Col,
} from "react-bootstrap";
import AdminNavbar from './layout/Navbar';
import Sidebar from "./layout/Sidebar";

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/paper-dashboard.min.css";
import "../../assets/demo/demo.css";

import { withRouter } from 'react-router-dom';
import  { userSignup } from "../../api";
import {RotatingLines} from 'react-loader-spinner';
import { Link } from "react-router-dom";
import { list } from "../../data/disctricts";
import { withTranslation } from 'react-i18next';

class UserSignupByAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            contactNumber: "",
            name: "",
            registrationId: "",
            role: "ASHA",
            district: "Ahmednagar",
            village: "",
            errors: {
                ...this.defaultErrors,
            }
        }
    }

    defaultErrors = {
        errMessage: "",
        contactNumber: "",
        name: "",
        registrationId: "",
        role: "",
        district: "",
        village: "",
    }

    validateForm = () => {      
        const {t} = this.props;  
        const contactNumber = this.state.contactNumber;
        const name = this.state.name;
        const registrationId = this.state.registrationId;
        const role = this.state.role;
        const village = this.state.village;
        const district = this.state.district;

        let valid = true;
        let errors = {};
        if(contactNumber == "") {
            errors["contactNumber"] = t("Please enter contact number");
            valid = false;
        } else if(contactNumber.length != 10) {
            errors["contactNumber"] = t("Please enter valid contact number");
            valid = false;
        }

        if(name == "") {
            valid = false;
            errors["name"] =  t("Please enter your name");
        }

        if(registrationId == "") {
            valid = false;
            errors["registrationId"] = t("Please enter your registration ID");
        } 

        if(role == "") {
            valid = false;
            errors["role"] = t("Please enter your role");
        } 

        if(village == "") {
            valid = false;
            errors["village"] = t("Please enter your village");
        } 

        if(district == "") {
            valid = false;
            errors["district"] = t("Please enter your district");   
        } 

        if(valid) {
            this.setState({
                errors: {
                    ...this.defaultErrors,
                }
            })
        } else {
            this.setState({
                errors: {
                    ...this.defaultErrors,
                }
            });

            this.setState({
                errors: {
                    ...this.defaultErrors,
                    ...errors,
                }
            })
        }
        return valid;
    }

    onFormSubmitHandler = (e) => {
        e.preventDefault();
        if(this.validateForm()) {
            // Start the loader
            this.setState({loader: true});

            // try to Signup
            userSignup({
                ...this.state
            }).then((res) => {
                alert("Your signed up");
                this.props.history.push("/login");
            }).catch((err) => {
                if(err.status == 422) {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            ...err.data,
                        }
                    })
                } else {
                    if(err?.data?.message) {
                        this.setState({
                            errors: {
                                ...this.state.errors,
                                errMessage: err?.data?.message
                            }
                        });
                    }
                }
                
            }).finally(() => {
                this.setState({loader: false});
            });
        }
        
    }
    render() {
        const {t} = this.props;
        return (
            <div className="wrapper ">
                <Sidebar/>
                <div className="main-panel">
                    <AdminNavbar/>
                    <div className="content">
                        <div className="h-v100 d-flex">
                            <Col md="12" className="p-4">
                                <Card className="p-4">
                                <Card.Header>
                                    <Card.Title as="h4">{t('Signup')} / {t('Registration')}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    {this.state.loader ? <RotatingLines width="100"/> :
                                    <Form onSubmit={this.onFormSubmitHandler} autoComplete="off">
                                        {this.state.errors.errMessage ? <small className="text-danger">{this.state.errors.errMessage}</small>: null}
                                        <Row>
                                            <Col className="pl-1 pr-1" md="12">
                                            <Form.Group>
                                                <label htmlFor="exampleInputEmail1">
                                                {t('Name')}
                                                </label>
                                                <Form.Control
                                                    autoComplete={"off"}
                                                    placeholder={t('Name')}
                                                    type="text"
                                                    min="10"
                                                    name="name"
                                                    value={this.state.name}
                                                    onChange={(e) => this.setState({name: e.target.value})}
                                                ></Form.Control>
                                                {this.state.errors.name ? <small className="text-danger">{this.state.errors.name}</small>: null}
                                            </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pl-1 pr-1" md="12">
                                            <Form.Group>
                                                <label htmlFor="exampleInputEmail1">
                                                    {t('Phone Number')}
                                                </label>
                                                <Form.Control
                                                    autoComplete={"off"}
                                                    placeholder={t('Phone Number')}
                                                    type="number"
                                                    name="contactNumber"
                                                    value={this.state.contactNumber}
                                                    onChange={(e) => this.setState({contactNumber: e.target.value})}
                                                ></Form.Control>
                                                {this.state.errors.contactNumber ? <small className="text-danger">{this.state.errors.contactNumber}</small>: null}
                                            </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pl-1 pr-1" md="12">
                                            <Form.Group>
                                                <label htmlFor="exampleInputEmail1">
                                                    {t('Registration Code')}
                                                </label>
                                                <Form.Control
                                                    autoComplete={"off"}
                                                    placeholder={t('Registration Code')}
                                                    type="text"
                                                    name="registrationId"
                                                    value={this.state.registrationId}
                                                    onChange={(e) => this.setState({registrationId: e.target.value})}
                                                ></Form.Control>
                                                {this.state.errors.registrationId ? <small className="text-danger">{this.state.errors.registrationId}</small>: null}
                                            </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pl-1 pr-1" md="12">
                                            <Form.Group>
                                                <label htmlFor="exampleInputEmail1">
                                                    {t('Role')}
                                                </label>
                                                <select defaultValue={this.state.role} autoComplete={"off"} className="form-control" name="role" onChange={(e) => this.setState({role: e.target.value})}>
                                                    <option value={"ASHA"}>ASHA</option>
                                                    <option value={"ANM"}>ANM</option>
                                                    <option value={"Anganwadi"}>Anganwadi</option>
                                                </select>
                                                {this.state.errors.role ? <small className="text-danger">{this.state.errors.role}</small>: null}
                                            </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pl-1 pr-1" md="12">
                                            <Form.Group>
                                                <label htmlFor="exampleInputEmail1">
                                                    {t('District')}
                                                </label>
                                                <select className="form-control" name="district" defaultValue={this.state.district} onChange={(e) => this.setState({district: e.target.value})}>
                                                    {list.states.find((state) => state.state == "Maharashtra").districts.map((district) => <option value={district}>{district}</option> )}
                                                </select>
                                                {this.state.errors.district ? <small className="text-danger">{this.state.errors.district}</small>: null}
                                            </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pl-1 pr-1" md="12">
                                            <Form.Group>
                                                <label htmlFor="exampleInputEmail1">
                                                    {t('Village')}
                                                </label>
                                                <Form.Control
                                                    autoComplete={"off"}
                                                    placeholder={t('Village')}
                                                    type="text"
                                                    name="village"
                                                    value={this.state.village}
                                                    onChange={(e) => this.setState({village: e.target.value})}
                                                ></Form.Control>
                                                {this.state.errors.village ? <small className="text-danger">{this.state.errors.village}</small>: null}
                                            </Form.Group>
                                            </Col>
                                        </Row>
                                        <Button
                                            className="btn-outline-info mt-2 ml-n2"
                                            type="submit"
                                            variant="default"
                                        >
                                            {t('Signup')}
                                        </Button>
                                        <div className="clearfix"></div>
                                    </Form>}
                                </Card.Body>
                                </Card>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(withRouter(UserSignupByAdmin));