import axios from 'axios';
import { createBrowserHistory } from 'history';

const api = axios.create({
    baseURL: 'https://ashaworkers.sansbrix.com/api',
    headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'X-Platform': 'web',
      },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    console.log("Token", token);
    if (token) {
        if(config.headers) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
            console.log("Header");
        }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      if (error.response.status === 401 || error.response.status === 419) {
        localStorage.clear();
        const history = createBrowserHistory({ forceRefresh: true });
        if (checkUnauthRoute(history.location.pathname) === false) {
          // history.push('/');
        }
      }
      const generic_error = 'Something went wrong.';
  
      /**
       * API sends an error message in the format
       * { error: error_message }
       */
      if (error.response && error.response.data) {
        /**
         * If the error is NOT initiated from the API (ie: for express/cloudflare errors)
         * then use generic error message for toast message and pass error info to sentry
         */
        if (typeof error.response.data === 'string') {
          const newData = {
            error: generic_error,
            info: error.response.data,
          };
          return Promise.reject(newData);
        }
        return Promise.reject(error.response);
      }
  
  
      if (error.message === 'Network Error') {
        error.message = "offline_error";
      }
      return Promise.reject(error.message);
    },
);

function checkUnauthRoute(params) {
    if (params === '/' || params === '/login') {
      return true;
    } else {
      return false;
    }
}
export default api;