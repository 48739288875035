import React from 'react';
import AdminNavbar from './layout/Navbar';
import Sidebar from "./layout/Sidebar";
import Swal from "sweetalert2";  

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/paper-dashboard.min.css";
import "../../assets/demo/demo.css";

import {RotatingLines} from 'react-loader-spinner';
import { approveUser, getUsersListing, cancelUser} from '../../api';
import { withTranslation } from 'react-i18next';

class UsersListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            listing: [],
        }
    }
    componentDidMount() {
        this.fetchUsersListing();
    }

    fetchUsersListing = () => {
        this.setState({loader: true});
        getUsersListing().then((response) => {
            this.setState({listing: [
                ...response.data,
            ]});
        }).finally(() => {
            this.setState({loader: false});
        })
    }

    onApproveUserHandler = (user) => {
        const {t} = this.props;
        Swal.fire({
            title: t('Are you sure?'),  
            text: t('You want to approve this user'),  
            icon: 'warning',  
            showCancelButton: true,  
            confirmButtonColor: '#3085d6',  
            cancelButtonColor: '#d33',  
            confirmButtonText: 'Yes!'  
        }).then((res) => {
            if(res.isConfirmed) {
                this.setState({loader: true});
                approveUser({
                    id: user["_id"],
                }).then((res) => {
                    this.fetchUsersListing();
                    Swal.fire({
                        icon: 'success',  
                        title: `${t('User Approved')} !`,  
                        text: t('User approved successfully'),
                    });
                    
                }).catch((err) => {

                }).finally(() => {
                    this.setState({loader: false});
                })
            }
        })
    }

    onCancelUserHandler = (user) => {
        const {t} = this.props;
        Swal.fire({
            title: t('Are you sure?'),  
            text: t('You want to cancel this user'),  
            icon: 'warning',  
            showCancelButton: true,  
            confirmButtonColor: '#3085d6',  
            cancelButtonColor: '#d33',  
            confirmButtonText: 'Yes!'  
        }).then((res) => {
            if(res.isConfirmed) {
                this.setState({loader: true});
                cancelUser({
                    id: user["_id"],
                }).then((res) => {
                    this.fetchUsersListing();
                    Swal.fire({
                        icon: 'success',  
                        title: t('User cancelled') +' !',  
                        text: t('User cancelled successfully'),
                    });
                    
                }).catch((err) => {

                }).finally(() => {
                    this.setState({loader: false});
                })
            }
        })
    }

    render() {
        const {t} = this.props;
        return (<>
            <div className="wrapper ">
                <Sidebar/>
                <div className="main-panel">
                    <AdminNavbar/>
                    <div className="content">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">{t('Users Listing')} </h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>
                                                        {t('S. No.')}
                                                    </th>
                                                    <th>
                                                        {t('Name')}
                                                    </th>
                                                    <th>
                                                        {t('Phone Number')}
                                                    </th>
                                                    <th>
                                                        {t('Registration Code')}
                                                    </th>
                                                    <th>
                                                        {t('Approve/Disapprove')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            {this.state.loader ? <RotatingLines />:
                                            <tbody>
                                                {Array.isArray(this.state.listing) && this.state.listing.map((list, index) => {return(
                                                    <tr>
                                                        <td>
                                                            {index+1}
                                                        </td>
                                                        <td>
                                                            {list.name}
                                                        </td>
                                                        <td>
                                                            {list.contactNumber}
                                                        </td>
                                                        <td>
                                                            {list.registrationId}
                                                        </td>
                                                        <td>
                                                            {!list.userApproved ? <button className='btn btn-success' onClick={() => this.onApproveUserHandler(list)}>{t('Approve')}</button> : <button className='btn btn-danger' onClick={() => this.onCancelUserHandler(list)}>{t('Cancel')}</button>}
                                                        </td>
                                                    </tr>
                                                )})} 
                                            </tbody>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

export default withTranslation()(UsersListing);