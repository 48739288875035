import React from "react";

import {
    Button,
    Card,
    Form,
    Row,
    Col,
} from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import  { submitOTP, userLogin } from "../../api";
import {RotatingLines} from 'react-loader-spinner';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class UserLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            otpSubmitted: false,
            loader: false,
            contactNumber: "",
            otp: "",
            errors: {
                errMessage: "",
                contactNumber: "",
                otp: "",
            }
        }
    }

    validateForm = () => {
        const contactNumber = this.state.contactNumber;
        const otp = this.state.otp;
        const otpSubmitted = this.state.otpSubmitted;
        
        let valid = true;
        if(contactNumber == "") {
            this.setState({errors : {...this.state.errors, contactNumber: "Please enter contact number"}});
            valid = false;
        } else if(contactNumber.length != 10) {
            this.setState({errors : {...this.state.errors, contactNumber: "Please enter valid contact number"}});
            valid = false;
        }

        if(otp == "" && otpSubmitted) {
            valid = false;
            this.setState({errors : {...this.state.errors, otp: "Please enter OTP"}});
        }

        if(valid) {
            this.setState({
                errors: {
                    errMessage: "",
                    contactNumber: "",
                    otp: "",
                }
            })
        }
        return valid;
    }

    changeanguageHandler = (lang) => {
        const {i18n} = this.props;
        i18n.changeLanguage(lang);
        localStorage.setItem("lang", lang);
    }

    onFormSubmitHandler = (e) => {
        e.preventDefault();
        if(this.validateForm()) {
            // Start the loader
            this.setState({loader: true});
            if(!this.state.otpSubmitted) {
                // try to login
                userLogin({
                    ...this.state
                }).then((res) => {
                    if(res.status) {
                        this.setState({otpSubmitted: true});
                    } else {
                        this.setState({
                            errors: {
                                ...this.state.errors,
                                errMessage: res.message,
                            }
                        });
                    }
                }).catch((err) => {
                    console.log("err", err.data);
                    if(err?.data?.message) {
                        this.setState({
                            errors: {
                                ...this.state.errors,
                                errMessage: err?.data?.message
                            }
                        });
                    }
                }).finally(() => {
                    this.setState({loader: false});
                });
            } else {
                submitOTP({
                    ...this.state
                }).then((res) => {
                    if(res.accessToken) {
                        alert("User login successfully.");
                        localStorage.setItem("accessToken", res.accessToken);
                        localStorage.setItem("role", "user");
                        localStorage.setItem("userApproved", res.userApproved);
                        this.props.history.push("/");
                    } else {
                        this.setState({
                            errors: {
                                ...this.state.errors,
                                errMessage: "Something went wrong. Try again later",
                            }
                        });
                    }
                }).catch((err) => {
                    console.log("err", err.data);
                    if(err?.data?.message) {
                        this.setState({
                            errors: {
                                ...this.state.errors,
                                errMessage: err?.data?.message
                            }
                        });
                    }
                }).finally(() => {
                    this.setState({loader: false});
                });
            }
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div className="h-v100 d-flex">
                <Col md="6" className="m-auto p-4">
                    <Card className="p-4">
                        <Card.Header>
                            <Card.Title as="h4">{t('Login Page')} ({t('User')})</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {this.state.loader ? <RotatingLines width="100"/> :
                            <Form onSubmit={this.onFormSubmitHandler} autoComplete="off">
                                {this.state.errors.errMessage ? <small className="text-danger ml-n2">{this.state.errors.errMessage}</small>: null}
                                <Row>
                                    <Col className="pl-1 pr-1" md="12">
                                    <Form.Group>
                                        <label htmlFor="exampleInputEmail1">
                                            {t('Phone Number')}
                                        </label>
                                        <Form.Control
                                            autoComplete={"off"}
                                            placeholder={t('Phone Number')}
                                            type="number"
                                            min="10"
                                            name="contactNumber"
                                            value={this.state.contactNumber}
                                            onChange={(e) => this.setState({contactNumber: e.target.value})}
                                        ></Form.Control>
                                        {this.state.errors.contactNumber ? <small className="text-danger">{this.state.errors.contactNumber}</small>: null}
                                    </Form.Group>
                                    </Col>
                                </Row>
                                {this.state.otpSubmitted ? 
                                <Row>
                                    <Col className="pl-1 pr-1" md="12">
                                    <Form.Group>
                                        <label>OTP</label>
                                        <Form.Control
                                            autoComplete={"off"}
                                            type="password"
                                            name="otp"
                                            value={this.state.otp}
                                            onChange={(e) => this.setState({otp: e.target.value})}
                                        ></Form.Control>
                                        {this.state.errors.otp ? <small className="text-danger">{this.state.errors.otp}</small>: null}
                                    </Form.Group>
                                    </Col>
                                </Row>: null}
                                <Button
                                    className="btn-outline-info mt-2 ml-n2"
                                    type="submit"
                                    variant="default"
                                >
                                    {t('Login')}
                                </Button>
                                <div className="clearfix"></div>
                                <Link to="/signup">{t('Not a User? Sign up.')}</Link>
                                <div className="mt-5">
                                    <div className="row">
                                        <label>Language : </label>
                                        <select defaultValue={this.props.i18n.language} className="form-control" onChange={(e) => this.changeanguageHandler(e.target.value) }>
                                            <option value={"ma"}>Marathi</option>
                                            <option value={"en"}>English</option>
                                        </select>
                                    </div>
                                </div>
                            </Form>}
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        )
    }
}

export default withTranslation()(withRouter(UserLogin));