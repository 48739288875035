import React from 'react';
import { withTranslation } from 'react-i18next';
import { en } from '../../../i8n/en';
import { withRouter } from 'react-router-dom';
import * as yup from "yup";
import $ from 'jquery';
import {Formik} from 'formik';
import { Col, Form, Row } from "react-bootstrap";
import SelectableButton from '../../../components/selectable-buttons';


const schema = yup.object({
    classificationOfDepressedPersonByCalling104: yup.string().required("** This field is required"),
    call108: yup.string().required("** This field is required"),
    placeSentForReferenceService: yup.string().required("** This field is required"),
    notes: yup.string().required("** This field is required"),
    phaseOfSurvey: yup.string().required("** This field is required")
});

class Preview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            formOneData : {},
            formTwoData : {},
            is_submitted: false,
            errors: [],
            formData: {
                ...this.props.formData
            },
            showSummaryQuestions: false,
        } 
    }

    componentDidMount() {
        this.getTotalScores();
        const {pathname} = this.props.location;
       if(pathname == "/create-phq12") {
        this.setState({showSummaryQuestions: true});
       }
    }

    updateChange = (errors) => {
        if(errors && Object.keys(errors).length == 0) {
            this.props.formValidatedFromThree(true);
            this.submitPreviewQuestions();
        } else { 
            this.props.formValidatedFromThree(false);
        }
    }

    submitPreviewQuestions = () => {
        const form = $("#preview-form").serializeArray();
        var data = {};
        $(form).each(function(index, obj){
            data[obj.name] = obj.value;
        });
        this.props.setFormFields(data);
    }

    renderSummaryQuestions = () => {
        const {is_submitted, errors, formData} = this.state;
        const t = this.props.t;
        return(
            <Formik
                enableReinitialize
                validationSchema={schema}
                initialValues={formData}
                initialErrors={errors}
                validateOnBlur={true}
                validateOnChange={true}
                validateOnMount={true}
            >
                {({ handleSubmit, handleChange, values, touched, errors, setFieldValue  }) => (
                    <Form
                        id="preview-form"
                        onSubmit={handleSubmit}
                        onChange={(e) => this.updateChange(errors)}
                        autoComplete="off"
                    >
                        <div className='mt-5 pl-5'>
                           
                            <div className='row'>
                                <div className="col-7">
                                    <label className='mr-2'>
                                        <b>1. {t('Classification of depressed person by calling 104')}</b>
                                    </label>
                                </div>
                                <div className="col-5">
                                    <SelectableButton
                                        preview={true}
                                        value={values.classificationOfDepressedPersonByCalling104}
                                        name={"classificationOfDepressedPersonByCalling104"}
                                        onChange={setFieldValue} 
                                        errors={errors} 
                                        updateChange={this.updateChange}/>
                                </div>
                                <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.classificationOfDepressedPersonByCalling104} </Form.Control.Feedback>
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className="col-7">
                                    <label className='mr-2'>
                                        <b>2. {t('Call 108? (1 if yes, 0 if not)')}</b>
                                    </label>
                                </div>
                                <div className="col-5">
                                    <SelectableButton
                                        preview={true}
                                        value={values.call108}
                                        name={"call108"}
                                        onChange={setFieldValue} 
                                        errors={errors} 
                                        updateChange={this.updateChange}/>
                                </div>
                                <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.call108} </Form.Control.Feedback>
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className="col-7">
                                    <label className='mr-2'>
                                        <b>3. {t('Place sent for reference service')}</b>
                                    </label>
                                </div>
                                <div className="col-5">
                                    <input type="text" onChange={handleChange} className='form-control' name='placeSentForReferenceService' value={values.placeSentForReferenceService}/>
                                </div>
                                <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.placeSentForReferenceService} </Form.Control.Feedback>
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className="col-7">
                                    <label className='mr-2'>
                                        <b>4. {t('Notes')}</b>
                                    </label>
                                </div>
                                <div className="col-5">
                                    <input type="text" onChange={handleChange} className='form-control' name='notes' value={values.notes}/>
                                </div>
                                <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.notes} </Form.Control.Feedback>
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className="col-7">
                                    <label className='mr-2'>
                                        <b>5. {t('Phase of survey')}</b>
                                    </label>
                                </div>
                                <div className="col-5">
                                    <input type="text" onChange={handleChange} className='form-control' name='phaseOfSurvey' value={values.phaseOfSurvey}/>
                                </div>
                                <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.phaseOfSurvey} </Form.Control.Feedback>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }

    clubInterviewQuestions = (formOne) => {
       if(formOne) {
           const formOneNames = Object.keys(formOne);
           const formOneLabels = Object.keys(en).slice(0, 17);
           
           var formOneData = {};
           for(let i=0; i<17; i++) {
            formOneData[formOneNames[i]] = formOneLabels[i];
           }
           return formOneData;
       }

       return {};
    }

    clubphq12Questions = (formTwo) => {
        if(formTwo) {
            const formOneNames = Object.keys(formTwo);
            const formOneLabels = Object.keys(en).slice(17, 29);
            
            var formOneData = {};
            for(let i=0; i<29 - 17; i++) {
             formOneData[formOneNames[i]] = formOneLabels[i];
            }
            return formOneData;
        }
 
        return {};
    }

    getTotalScores = () => {
        var total = 0;
        Object.keys(this.props.formTwo).forEach((k) => {
            if(this.props.formTwo[k] === "yes") {
                total+= 1;
            }
        });

        this.setState({total: total});
    }

    render() {
        const {formOne, formTwo} = this.props;
        const t = this.props.t;

        const InterviewQuestions = this.clubInterviewQuestions(formOne);
        const phq12Questions = this.clubphq12Questions(formTwo);

        return(
            <div className='m-5 summary'>
                <p><h4>{t('interview-questionnaire-title')}</h4></p>
                <hr/>            
                {Object.keys(InterviewQuestions).length > 0 && Object.keys(InterviewQuestions).map((field, index) => 
                    <div className='form-group pl-5' key={`a-${index}`}>
                        <label>
                            <b style={{color: 'grey'}}>{t(InterviewQuestions[field])} :</b>
                        </label>
                        <span className='ml-3'><b>{t(formOne[field])}</b></span>
                    </div>
                )}

                <p className='mt-5'><h4>{t('phq12-title')}</h4></p> 
                <hr/>            
                {Object.keys(phq12Questions).length > 0 && Object.keys(phq12Questions).map((field, index) => 
                    <div className='form-group pl-5' key={`b-${index}`}>
                        <label>
                            <b style={{color: 'grey'}}>{t(phq12Questions[field])} :</b>
                        </label>
                        <span className='ml-3'>{t(formTwo[field])}</span>
                    </div>
                )}
                <hr/>
                {this.state.showSummaryQuestions ? <>
                    Total Score : ({this.state.total}) 
                    {this.renderSummaryQuestions()}
                </> : null}
            </div>
        )
    }
}


export default withTranslation()(withRouter(Preview));