import React from 'react';
import { withTranslation } from 'react-i18next';
import SelectableButton from '../../../components/selectable-buttons';
import {Formik} from 'formik';
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import $ from 'jquery';

const schema = yup.object({
    feelingSadBlueOrDepressed: yup.string().required("** This field is required"),
    lossOfInterestOrPleasureInMostOfTheThings: yup.string().required("** This field is required"),
    feelingTiredOrLowOnEnergyMostOfTheTimes: yup.string().required("** This field is required"),
    lossOfAppetiteOrWeightLoss: yup.string().required("** This field is required"),
    overeatingOrWeightGain: yup.string().required("** This field is required"),
    troubleFallingAsleepOrStayingAsleep: yup.string().required("** This field is required"),
    sleepingTooMuch: yup.string().required("** This field is required"),
    moreTroubleThanUsualConcentratingOnThings: yup.string().required("** This field is required"),
    feelingDownOnYourselfNoGoodOrWorthless: yup.string().required("** This field is required"),
    beingFidgetyOrRestlessThatYouMoveAroundALotThanUsual: yup.string().required("** This field is required"),
    movedOrSpokeSoSlowlyThatOtherPeopleCouldHaveNoticed: yup.string().required("** This field is required"),
    thoughtAboutDeathMoreThanUsualEitherYourOwnOrSomeoneElseOrDeathInGeneral: yup.string().required("** This field is required"),
});


class PHQ12 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_submitted: false,
            errors: [],
            formData: {
                ...this.props.formData
            }
        }
    }

    submitPhq12Questions = () => {
        const form = $("#add-application-form").serializeArray();
        var data = {};
        $(form).each(function(index, obj){
            data[obj.name] = obj.value;
        });
        this.props.setFormFields(data);
    }

    handleSubmit = (e) => {}

    updateChange = (errors) => {
        if(errors && Object.keys(errors).length == 0) {
            this.props.formVaidatedFromTwo(true);
            this.submitPhq12Questions();
        } else { 
            this.props.formVaidatedFromTwo(false);
        }
    }

    render() {
        const {is_submitted, errors, formData} = this.state;
        const t = this.props.t;
        return(
            <Formik
                    enableReinitialize
                    validationSchema={schema}
                    onSubmit={(e) => this.handleSubmit(e)}
                    initialValues={formData}
                    initialErrors={errors}
                    validateOnBlur={true}
                    validateOnChange={true}
                    validateOnMount={true}
                >
                    {({ handleSubmit, handleChange, values, touched, errors, setFieldValue  }) => (
                        
                        <Form
                            id="add-application-form"
                            onSubmit={handleSubmit}
                            onChange={(e) => this.updateChange(errors)}
                            autoComplete="off"
                        >
                            <div className='m-5'>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-2'>
                                            <b>1. {t('Feeling Sad Blue or depressed')}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            value={values.feelingSadBlueOrDepressed}
                                            name={"feelingSadBlueOrDepressed"}
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.feelingSadBlueOrDepressed} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-2'>
                                            <b>2. {t('Loss of interest or pleasure in most of the things')}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            value={values.lossOfInterestOrPleasureInMostOfTheThings}
                                            name={"lossOfInterestOrPleasureInMostOfTheThings"}
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.lossOfInterestOrPleasureInMostOfTheThings} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-2'>
                                            <b>3. {t('Feeling tired or low on energy most of the times')}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            value={values.feelingTiredOrLowOnEnergyMostOfTheTimes}
                                            name={"feelingTiredOrLowOnEnergyMostOfTheTimes"}
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.feelingTiredOrLowOnEnergyMostOfTheTimes} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-2'>
                                            <b>4. {t('Loss of appetite or weight loss')}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            value={values.lossOfAppetiteOrWeightLoss}
                                            name={"lossOfAppetiteOrWeightLoss"}
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.lossOfAppetiteOrWeightLoss} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-2'>
                                            <b>5. {t('Overeating or weight gain')}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            value={values.overeatingOrWeightGain}
                                            name={"overeatingOrWeightGain"}
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.overeatingOrWeightGain} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-2'>
                                            <b>6. {t('Trouble falling asleep or staying asleep')}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            value={values.troubleFallingAsleepOrStayingAsleep}
                                            name={"troubleFallingAsleepOrStayingAsleep"}
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.troubleFallingAsleepOrStayingAsleep} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-2'>
                                            <b>7. {t('Sleeping too much')}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            value={values.sleepingTooMuch}
                                            name={"sleepingTooMuch"}
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.sleepingTooMuch} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-2'>
                                            <b>8. {t('More trouble than usual concentrating on things')}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            value={values.moreTroubleThanUsualConcentratingOnThings}
                                            name={"moreTroubleThanUsualConcentratingOnThings"}
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.moreTroubleThanUsualConcentratingOnThings} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-2'>
                                            <b>9. {t('Feeling down on yourself, no good or worthless')}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            value={values.feelingDownOnYourselfNoGoodOrWorthless}
                                            name={"feelingDownOnYourselfNoGoodOrWorthless"}
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.feelingDownOnYourselfNoGoodOrWorthless} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-2'>
                                            <b>10. {t('Being fidgety or restless that you move around a lot than usual')}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            value={values.beingFidgetyOrRestlessThatYouMoveAroundALotThanUsual}
                                            name={"beingFidgetyOrRestlessThatYouMoveAroundALotThanUsual"}
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.beingFidgetyOrRestlessThatYouMoveAroundALotThanUsual} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-2'>
                                            <b>11. {t('Moved or spoke so slowly that other people could have noticed')}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            value={values.movedOrSpokeSoSlowlyThatOtherPeopleCouldHaveNoticed}
                                            name={"movedOrSpokeSoSlowlyThatOtherPeopleCouldHaveNoticed"}
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.movedOrSpokeSoSlowlyThatOtherPeopleCouldHaveNoticed} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-2'>
                                            <b>12. {t(`Thought about death more than usual; either your own or someone else's, or death in general.`)}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            value={values.thoughtAboutDeathMoreThanUsualEitherYourOwnOrSomeoneElseOrDeathInGeneral}
                                            name={"thoughtAboutDeathMoreThanUsualEitherYourOwnOrSomeoneElseOrDeathInGeneral"}
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.thoughtAboutDeathMoreThanUsualEitherYourOwnOrSomeoneElseOrDeathInGeneral} </Form.Control.Feedback>
                                </div>
                            </div> 
                        </Form>
                    )}
            </Formik>
        );
    }
}

export default  withTranslation()(PHQ12);