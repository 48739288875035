import api from './api';

const adminLogin = (data) => api.post("/signin-admin", data);

const userSignup = (data) => api.post("/signup", data);
const userLogin = (data) => api.post("/signin", data);
const submitOTP = (data) => api.post("/submit-otp", data);

const getUnApprovedUsers = () => api.post("/list-non-approved-users");
const getUsersListing = () => api.post("/list-users");

const approveUser = (data) => api.post("/approve-user", data);
const cancelUser = (data) => api.post("/cancel-user", data);

const submitSurveyForm = (data) => api.post("/survey", data);
const surveyListing = (data) => api.get("/survey");
const getSurvey = data => api.post('/get-survey', data);

export {
    getUnApprovedUsers,
    adminLogin,
    getUsersListing,
    approveUser,
    userLogin,
    submitOTP,
    userSignup,
    submitSurveyForm,
    surveyListing,
    cancelUser,
    getSurvey
}