import * as React from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';

export default function SelectableButton(props) {
  const { t, i18n } = useTranslation();

  const [view, setView] = React.useState(props.value);

  const handleChange = (event, nextView) => {
    setView(nextView);
    props.onChange(props.name, nextView);
  };

  React.useEffect(() => {
    if(props.updateChange) {
      props.updateChange(props.errors);
    }
    
  }, [props.errors])

  return (
    <>  
        <input type="hidden" name={props.name} value={view ?? ''} />
        <ToggleButtonGroup
          className='w-100 select-buttons'
          orientation="vertical"
          value={view}
          exclusive
          onChange={handleChange}
        >
        <ToggleButton value="yes" aria-label="">
            <div className='w-100'>
                <span className='pull-left'>{t('yes')}</span>  {!props.preview ? <span className='pull-right'>+1</span> : null}
            </div>
            
        </ToggleButton>
        <ToggleButton value="no" aria-label="module">
            <div className='w-100'>   
                <span className='pull-left'>{t('no')}</span> {!props.preview ? <span className='pull-right'>0</span> : null}
            </div>
        </ToggleButton>
        </ToggleButtonGroup>
    </>
  );
}