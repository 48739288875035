
import React from 'react';

import AdminNavbar from './layout/Navbar';
import Sidebar from "./layout/Sidebar";

import { surveyListing } from '../../api';
import { withTranslation } from 'react-i18next';

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/paper-dashboard.min.css";
import "../../assets/demo/demo.css";
import { Link } from 'react-router-dom';

class SurveyListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listing: [],
        }
    }

    componentDidMount() {
        surveyListing().then((res) => {
            this.setState({listing: res.data});
        })
    }

    navigateToPreviewPage = (id) => {
        this.props.history.push(`/survey/${id}`);
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <div className="wrapper ">
                    <Sidebar/>
                    <div className="main-panel">
                        <AdminNavbar/>
                        <div className="content">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className='col'>
                                                <h4 className="card-title pull-left"> {t('Surveys')} </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>
                                                            {t('S. No.')}
                                                        </th>
                                                        <th>
                                                            {t('Head Of Family')}
                                                        </th>
                                                        <th>
                                                            {t('Age')}
                                                        </th>
                                                        <th>
                                                            {t('Gender')}
                                                        </th>
                                                        <th>
                                                            {t('Marital Status')}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.listing.map((list, index) => {
                                                        return(
                                                            <tr style={{cursor: 'pointer'}} key={index} onClick={() => this.navigateToPreviewPage(list["_id"])}>
                                                                <td>{index+1}</td>
                                                                <td>{list.headOfFamily}</td>
                                                                <td>{list.age}</td>
                                                                <td>{list.gender}</td>
                                                                <td>{list.maritalStatus}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


export default withTranslation()(SurveyListing);