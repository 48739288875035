import React from 'react';
import { getSurvey } from '../../api';
import { withRouter } from 'react-router-dom';
import Preview from './survey/preview';
import { RotatingLines } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { interviewQuestionformField, phq12FormFields } from './survey/partials/fields';

class SurveyPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            formOne: {},
            formTwo: {},
            pathName: "",
        }
    }

    componentDidMount() {
        if(this.props.location.pathname.includes("/admin")) {
            this.setState({pathName: "/admin"})
        } else {
            this.setState({pathName: "/"});
        }
        const id = this.props.match.params.surveyId;
        getSurvey({
            id: id
        }).then((res) => {
            const survey = {
                ...interviewQuestionformField,
                ...res.survey
            }

            var phq12 = {};
            Object.keys(phq12FormFields).forEach((key) => {
                phq12[key] = res.phq12[key];

            })
            console.log(phq12);
            this.setState({formOne: survey, formTwo: phq12});
        }).finally(() => this.setState({loader: false}));
    }

    render() {
        const {t} = this.props;
        if(this.state.loader) {
            return <RotatingLines width='100'/>
        } else {
            return(
                <>  
                    <Link to={this.state.pathName}><h4 className='ml-5'>{t("Go Back")}</h4></Link>
                    <Preview formOne={this.state.formOne} formTwo={this.state.formTwo}/>
                </>
                
            )
        }
        
    }
}

export default withTranslation()(withRouter(SurveyPreview));