export const en = {
  "Name of the head of the family" : "Name of the head of the family",
  "Age": "Age", 
  "Gender" : "Gender",
  "Marital Status": "Marital Status",
  "Education": "Education",
  "Is the annual household income sufficient?": "Is the annual household income sufficient?",
  "Apart from farming, do you have any other occupation (goat, poultry or anything else)": "Apart from farming, do you have any other occupation (goat, poultry or anything else)",
  "Do you have any debt left ?": "Do you have any debt left ?",
  "Where do you get loans from": "Where do you get loans from",
  "Does debt or any other cause cause family strife?": "Does debt or any other cause cause family strife?",
  "Do you face any kind of mental stress due to your children's marriage or their young age or any other reasons?": "Do you face any kind of mental stress due to your children's marriage or their young age or any other reasons?",
  "Do you or anyone else in the house has any kind of addiction?": "Do you or anyone else in the house has any kind of addiction?",
  "Does anyone in the house have a major illness?": "Does anyone in the house have a major illness?",
  "Has anyone committed suicide at home or among relatives? Or attempted suicide?": "Has anyone committed suicide at home or among relatives? Or attempted suicide?",
  "Is anyone unemployed at your home?": "Is anyone unemployed at your home?",
  "Has barrenness, natural disasters or other causes caused financial hardship?": "Has barrenness, natural disasters or other causes caused financial hardship?",
  "Is the card of Ayushyaman Bharat or Mahatma Jotiba Phule Jan Arogya Yojana issued? And does it benefit the sick?": "Is the card of Ayushyaman Bharat or Mahatma Jotiba Phule Jan Arogya Yojana issued? And does it benefit the sick?",

  // PHQ - 12
  "Feeling Sad Blue or depressed": "Feeling Sad Blue or depressed",
  "Loss of interest or pleasure in most of the things": "Loss of interest or pleasure in most of the things",
  "Feeling tired or low on energy most of the times": "Feeling tired or low on energy most of the times",
  "Loss of appetite or weight loss": "Loss of appetite or weight loss",
  "Overeating or weight gain": "Overeating or weight gain",
  "Trouble falling asleep or staying asleep": "Trouble falling asleep or staying asleep",
  "Sleeping too much": "Sleeping too much",
  "More trouble than usual concentrating on things": "More trouble than usual concentrating on things",
  "Feeling down on yourself, no good or worthless": "Feeling down on yourself, no good or worthless",
  "Being fidgety or restless that you move around a lot than usual": "Being fidgety or restless that you move around a lot than usual",
  "Moved or spoke so slowly that other people could have noticed": "Moved or spoke so slowly that other people could have noticed",
  "Thought about death more than usual; either your own or someone else's, or death in general.": "Thought about death more than usual; either your own or someone else's, or death in general.",

  // Components
  'Back': 'Back',
  'Next': 'Next',

  'Married': 'Married',
  'Unmarried': 'Unmarried',
  'Divorced': 'Divorced',
  'Other': 'Other',
  
  'Uneducated': 'Uneducated',
  'Primary': 'Primary',
  'Middle School': 'Middle School',
  'High School': 'High School',
  'Intermediate': 'Intermediate',
  'Diploma': 'Diploma',
  'Graduate': 'Graduate',
  'Post Graduate': 'Post Graduate',
  'Doctorate (PhD)': 'Doctorate (PhD)',
  'Other': 'Other',

  'interview-questionnaire-title': '" Prerna Project " Farmer Counseling Health Services Program, Yavatmal',
  'phq12-title': '" Prerna Project " Farmer Counseling Health Services Program, Yavatmal Patient Health Questionnaire P.H.Q.-12',

  'Classification of depressed person by calling 104' : 'Classification of depressed person by calling 104',
  'Call 108? (1 if yes, 0 if not)': 'Call 108? (1 if yes, 0 if not)',
  'Place sent for reference service': 'Place sent for reference service',
  'Notes': 'Notes',
  'Phase of survey': 'Phase of survey',

  'yes': 'Yes',
  'no': 'No',

  'Login Page' : 'Login Page',
  'User': 'User',
  'Phone Number' : 'Phone Number',
  'Not a User? Sign up.': 'Not a User? Sign up.',
  'OTP' : 'OTP',
  'Login': 'Login',
  'Signup': 'Signup',
  'Registration': 'Registration',
  'Name' : 'Name',
  'Registration Code': 'Registration Code',
  'Role': 'Role',
  'District' : 'District',
  'Village' : 'Village',
  'Already a user? Log in.': 'Already a user? Log in.',
  'Signup' : 'Signup',
  'Please enter your name' : 'Please enter your name',
  'Please enter contact number': 'Please enter contact number',
  'Please enter your registration ID': 'Please enter your registration ID',
  'Please enter your village': 'Please enter your village',

  'Dashboard': 'Dashboard',
  'Surveys Listing': 'Surveys Listing',
  'Add Survey': 'Add Survey',
  'Logout': 'Logout',
  'Surveys': 'Surveys',
  'Unsent' : 'Unsent',
  'Submit Unsent': 'Submit Unsent',
  'Head Of Family': 'Head Of Family',
  'S. No.': 'S. No.',
  'Submit Unsent': 'Submit Unsent',
  "You've not been approved yet": "You've not been approved yet",
  "Go Back": "Go Back",
  "Finish": "Finish",
  "Password": "Password",
  "Please enter password": "Please enter password",
  "Users Listing": "Users Listing",
  "Approve/Disapprove": "Approve/Disapprove",
  "Approve": "Approve",
  "Cancel": "Cancel",
  "Are you sure?": "Are you sure?",
  'You want to approve this user':'You want to approve this user',
  'User Approved': 'User Approved',
  'User approved successfully': 'User approved successfully',
  'You want to cancel this user': 'You want to cancel this user',
  'User cancelled': 'User cancelled',
  'User cancelled successfully' : 'User cancelled successfully',
  'Survey Created': 'Survey Created',
  'Survey Created Successfully': 'Survey Created Successfully',
  'submit survey when you connect with internet': 'submit survey when you connect with internet'
}; 