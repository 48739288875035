import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { requireAuthentication } from './hoc/requireAuthentication';
import AdminDashboard from './views/admin/AdminDashboard';
import AdminLogin from './views/admin/AdminLogin';
import UsersListing from './views/admin/UsersListing';
import Survey from './views/user/survey';
import SurveyListing from './views/user/SurveyListing';
import UserDashboard from './views/user/UserDashboard';
import UserLogin from './views/user/UserLogin';
import UserSignup from './views/user/UserSignup';
import AdminSurveyListing from './views/admin/AdminSurveyListing';
import SurveyPreview from './views/user/SurveyPreview';
import UserSignupByAdmin from './views/admin/UserSignupByAdmin';

class Routes extends Component { 
    render() {
        return(
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={requireAuthentication(UserDashboard, "user")}/>
                    <Route exact path="/create-phq12" component={requireAuthentication(Survey, "user")}/>
                    <Route exact path="/survey-listing" component={requireAuthentication(SurveyListing, "user")}/>
                    <Route exact path="/survey/:surveyId" component={requireAuthentication(SurveyPreview, "user")}/>
                    <Route exact path='/signup' component={UserSignup} />
                    <Route exact path='/login' component={UserLogin} />

                    <Route exact path='/admin' component={requireAuthentication(AdminDashboard, "admin")} />
                    <Route exact path="/admin/survey-listing" component={requireAuthentication(AdminSurveyListing, "admin")}/>
                    <Route exact path='/admin/list-users' component={requireAuthentication(UsersListing, "admin")} />
                    <Route exact path="/admin/survey/:surveyId" component={requireAuthentication(SurveyPreview, "admin")}/>
                    <Route exact path="/admin/user/signup" component={requireAuthentication(UserSignupByAdmin, "admin")}/>
                    <Route exact path='/admin/login' component={AdminLogin} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Routes;