export const ma = { 
  "Name of the head of the family" : "कुटुंब प्रमुखाचे नाव",
  "Age": "वय", 
  "Gender" : "लिंग",
  "Marital Status": "वैवाहिक स्थिती",
  "Education": "शिक्षण",
  "Is the annual household income sufficient?": "घराचे वार्षिक उत्पन्न पुरेसे आहे का ?",
  "Apart from farming, do you have any other occupation (goat, poultry or anything else)": "शेती व्यतिरिक्त आपण काही जोड धंदा (शेळी, कुकूटपालन किंवा अन्य काही करता का?",
  "Do you have any debt left ?": "तुमच्यावर थकबाकीत कर्ज आहे का?",
  "Where do you get loans from": "कर्ज कुठून घेतात",
  "Does debt or any other cause cause family strife?": "कर्जामुळे किंवा इतर कुठल्याही कारणामुळे कौटुंबिक कलह निर्माण होतात का ?",
  "Do you face any kind of mental stress due to your children's marriage or their young age or any other reasons?": "मुला /मुलींच्या विवाहामुळे किंवा बाळांतपण किंवा इतर कारणामुळे मानसिक ताण येतो का ?",
  "Do you or anyone else in the house has any kind of addiction?": "तुम्हाला किंवा घरात इतर कोणत्याही व्यक्तीस व्यसन आहे का?",
  "Does anyone in the house have a major illness?": "घरात कोणाला कोणता मोठा आजार आहे का ?",
  "Has anyone committed suicide at home or among relatives? Or attempted suicide?": "घरात किंवा नातेवाईकांत कोणी आत्महत्या केली आहे का? किंवा आत्महत्या करण्याचा प्रयत्न केला आहे का?",
  "Is anyone unemployed at your home?": "घरात कोणी बेरोजगार आहे का?",
  "Has barrenness, natural disasters or other causes caused financial hardship?": "नापिकी, नैसर्गिक आपत्ती किंवा इतर करण्यामुळे आर्थिक अडचण निर्माण झाली आहे का ?",
  "Is the card of Ayushyaman Bharat or Mahatma Jotiba Phule Jan Arogya Yojana issued? And does it benefit the sick?": "आयुष्यमान भारत किंवा महात्मा जोतीबा  फुले जन आरोग्य योजनेचे कार्ड काढले आहे का? व त्याचा आजारपणात लाभ मिळतो का?",

   // PHQ - 12
   "Feeling Sad Blue or depressed": "नेहमी उदास किंवा निराश वाटते का ?",
   "Loss of interest or pleasure in most of the things": "जास्तीत जास्त गोष्टी मध्ये आनंद किंवा रस वाटत नाही का ?",
   "Feeling tired or low on energy most of the times": "बराच वेळ थकवा जाणवतो का?",
   "Loss of appetite or weight loss": "भूक कमी लागते अथवा वजन खूप कमी झाले आहे का ?",
   "Overeating or weight gain": "भूक जास्त लागते अथवा वजन खूप वाढत आहे का ?",
   "Trouble falling asleep or staying asleep": "झोप लागत नाही किंवा अर्धवट झोप होते का ?",
   "Sleeping too much": "अति जास्त प्रमाणात झोप येते का?",
   "More trouble than usual concentrating on things": "कामावर / इतर गोष्टीवर लक्ष केंद्रीत करण्यास नेहमी पेक्षा जास्त अडचण येते का?",
   "Feeling down on yourself, no good or worthless": "असहाय्यता स्वतः बद्दल तीर्व नाराजी किंवा निरर्थकता वाटते का ?",
   "Being fidgety or restless that you move around a lot than usual": "खूप अस्वस्थ वाटून वारंवार चकरा माराव्या असे वाटते का?",
   "Moved or spoke so slowly that other people could have noticed": "शारीरिक हालचाली व बोलणे लक्षात येणे एवढे मंदावले आहे का ?",
   "Thought about death more than usual; either your own or someone else's, or death in general.": "स्वतःच्या / दुसऱ्याची किंवा एकंदरीतच मृत्यू बद्दल नेहमीपेक्षा जास्त विचार मनात येतात का ?",

   // Components
  'Back': 'परत',
  'Next': 'पुढे',

  'Married': 'विवाहित',
  'Unmarried': 'अविवाहित',
  'Divorced': 'घटस्फोटि',
  'Other': 'इतर',
  
  'Uneducated': 'अशिक्षित',
  'Primary': 'प्राथमिक',
  'Middle School': 'माध्यमिक शाळा',
  'High School': 'हायस्कूल',
  'Intermediate': 'मध्यवर्ती',
  'Diploma': 'डिप्लोमा',
  'Graduate': 'पदवीधर',
  'Post Graduate': 'पदव्युत्तर',
  'Doctorate (PhD)': 'डॉक्टरेट (पीएचडी)',
  'Other': 'इतर',

  'interview-questionnaire-title': '" प्रेरणा प्रकल्प " शेतकरी समुपदेशन आरोग्य सेवा कार्यक्रम ,यवतमाळ',
  'phq12-title': '" प्रेरणा प्रकल्प " शेतकरी समुपदेशन आरोग्य सेवा कार्यक्रम ,यवतमाळ रुग्ण आरोग्य प्रश्नावली पी. एच क्यू ',

  'Classification of depressed person by calling 104' : '१०४ ला कॉल करून नैराश्यग्रस्त व्यक्तीचे केलेले वर्गीकरण',
  'Call 108? (1 if yes, 0 if not)' : '१०८ ला कॉल केला का? (होय असल्यास १, नाही असल्यास ० नमूद करावा )',
  'Place sent for reference service' : 'संदर्भ सेवेसाठी पाठविण्यात आलेले ठिकाण ',
  'Notes' : 'शेरा',
  'Phase of survey' : 'सर्वेक्षणाचा टप्पा',

  'yes': 'होय',
  'no': 'नाही',

  'Login Page' : 'लॉगिन पृष्ठ',
  'User': 'वापरकर्ता',
  'Phone Number': 'फोन नंबर',
  'Not a User? Sign up.': 'वापरकर्ता नाही? साइन अप करा.',
  'OTP': 'OTP',
  'Login': 'लॉगिन करा',
  'Signup': 'साइनअप करा',
  'Registration': 'नोंदणी',
  'Name' : 'नाव',
  'Registration Code' : 'नोंदणी कोड',
  'Role': 'भूमिका',
  'District' : 'जिल्हा',
  'Village' : 'गाव',
  'Already a user? Log in.' : "आधीपासूनच वापरकर्ता आहात? लॉग इन करा.",
  'Signup' : 'साइनअप करा',
  'Please enter your name' : 'कृपया आपले नाव प्रविष्ट करा',
  'Please enter contact number': 'कृपया संपर्क क्रमांक प्रविष्ट करा',
  'Please enter your registration ID': 'कृपया तुमचा नोंदणी आयडी प्रविष्ट करा',
  'Please enter your village': 'कृपया आपल्या गावात प्रवेश करा',

  'Dashboard': 'डॅशबोर्ड',
  'Surveys Listing': 'सर्वेक्षण सूची',
  'Add Survey': 'सर्वेक्षण जोडा',
  'Logout': 'बाहेर पडणे',
  'Surveys': 'सर्वेक्षण',
  'Unsent' : 'न पाठवलेला',
  'Submit Unsent': 'न पाठवलेला सबमिट करा',
  'Head Of Family': 'कुटुंब प्रमुख',
  'S. No.': 'अनुक्रमांक',
  'Submit Unsent': 'न पाठवलेला सबमिट करा',
  "You've not been approved yet": "तुम्हाला अद्याप मान्यता मिळालेली नाही",
  "Go Back": "परत जा",
  "Finish": "समाप्त करा",
  "Password": "पासवर्ड",
  "Please enter password": "कृपया पासवर्ड टाका",
  "Users Listing": "वापरकर्ते सूची",
  "Approve/Disapprove": "मंजूर/नाकारणे",
  "Approve": "मंजूर",
  "Cancel": "रद्द करा",
  "Are you sure?": "तुला खात्री आहे?",
  'You want to approve this user': 'तुम्हाला या वापरकर्त्याला मान्यता द्यायची आहे',
  'User Approved': 'वापरकर्ता मंजूर',
  'User approved successfully': 'वापरकर्ता यशस्वीरित्या मंजूर',
  'You want to cancel this user': 'तुम्हाला हा वापरकर्ता रद्द करायचा आहे',
  'User cancelled': 'वापरकर्त्याने रद्द केले',
  'User cancelled successfully': 'वापरकर्त्याने यशस्वीरित्या रद्द केले',
  'Survey Created': 'सर्वेक्षण तयार',
  'Survey Created Successfully': 'सर्वेक्षण यशस्वीरित्या तयार केले',
  'submit survey when you connect with internet': 'तुम्ही इंटरनेटशी कनेक्ट झाल्यावर सर्वेक्षण सबमिट करा'
}