import React from "react";
import { withRouter } from 'react-router-dom';

export function requireAuthentication(Component, role) {
    return class AuthenticatedComponent extends React.Component {

        /**
         * Check if the user is authenticated, this.props.isAuthenticated
         * has to be set from your application logic (or use react-redux to retrieve it from global state).
         */

        componentDidMount() {
            this.isAuthenticated();
        }

        isAuthenticated() {
            // if(localStorage.getItem("role") && localStorage.getItem("accessToken")) {
                if(role == "admin") {
                    if(localStorage.getItem("role") == "admin") {
                        return true;
                    } else {
                        this.props.history.push("/admin/login");
                    }
                } else if(role == "user") {
                    if(localStorage.getItem("role") == "user") {
                        return true;
                    } else {
                        this.props.history.push("/login");
                    }
                }
            // } else {
            //     this.props.history.push("/login");
            // }
        }

        /**
         * Render
         */
        render() {
            return (
                <Component {...this.props} />
            );
        }
    };
}

export default withRouter(requireAuthentication);