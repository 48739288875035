import React from 'react';
import { Link } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import * as $ from 'jquery';
import { withTranslation } from 'react-i18next';

class AdminNavbar extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        showTogller: false,
      }
    }

    onLogoutButtonClickHandler = (e) => {
      e.preventDefault();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("role");
      this.props.history.push("/login");
    }

    render() {
        const {t} = this.props;
        return (
          <nav className="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent" onClick={() => this.setState({showTogller : !this.state.showTogller})}>
            <div className="container-fluid">
              <div className="navbar-wrapper">
                <div className="navbar-toggle">
                  <button type="button" className="navbar-toggler" >
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </button>
                </div>
                <a className="navbar-brand" href="javascript:;">Dashboard</a>
              </div>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
              </button>
              <div className={`collapse navbar-collapse justify-content-end ${this.state.showTogller ? 'd-block' : 'd-none'}`} id="navigation">
                <ul className="navbar-nav">
                <li className="nav-item side-nav-options">
                    <a className="nav-link btn-rotate">
                      <Link to="/">
                        <i className="nc-icon nc-bank" />
                        <p>{t('Dashboard')}</p>
                      </Link>
                    </a>
                  </li>
                  <li className="nav-item side-nav-options">
                    <a className="nav-link btn-rotate">
                      <Link to="/survey-listing">
                        <i className="nc-icon nc-bank" />
                        <p>{t('Surveys Listing')}</p>
                      </Link>
                    </a>
                  </li>
                  <li className="nav-item side-nav-options">
                    <a className="nav-link btn-rotate">
                      <Link to="/create-phq12">
                        <i className="nc-icon nc-bank" />
                        <p>{t('Add Survey')}</p>
                      </Link>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link btn-rotate" onClick={this.onLogoutButtonClickHandler}>
                      <i className="nc-icon nc-settings-gear-65" /> {t('Logout')}
                      <p>
                        <span className="d-lg-none d-md-block">Account</span>
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        );
      }
}

export default withTranslation()(withRouter(AdminNavbar));
