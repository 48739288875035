import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InterviewQuestion from './interview-question';
import Phq12 from './phq12';
import {interviewQuestionformField, phq12FormFields, summaryQuestions} from './partials/fields';
import Preview from './preview';
import { submitSurveyForm } from '../../../api';
import {RotatingLines} from 'react-loader-spinner';
import Swal from "sweetalert2";
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const steps = ['Interview Questionnaire', 'PHQ12', 'Preview'];

function Survey(props) {
  const { t, i18n } = useTranslation();

  const [loader, setLoader] = React.useState(false);

  const [userApproved, setUserApproved] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [formOneValidated, setFormOneValidated] = React.useState(false);
  const [formTwoVaidated, setFormTwoValidated] = React.useState(false);
  const [formThreeValidated, setFormThreeValidated] = React.useState(false);

  const [formOneFields, setFormOneFields] = React.useState({...interviewQuestionformField});
  const [formTwoFields, setFormTwoFields] = React.useState({...phq12FormFields});
  const [formThreeFields, setFormThreeFields] = React.useState({...summaryQuestions});

  const formVaidatedFromOne = (flag) => {
    setFormOneValidated(flag);
  }

  const formVaidatedFromTwo = (flag) => {
    setFormTwoValidated(flag);
  }

  const formValidatedFromThree = (flag) => {
    setFormThreeValidated(flag);
  }
  
  React.useEffect(() => {
    const userApproved = localStorage.getItem("userApproved");
    setUserApproved(eval(userApproved));
  }, []);

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if(activeStep == 0 && !formOneValidated) {
      return;
    }

    if(activeStep == 1 && !formTwoVaidated) {
      return;
    }

     if(activeStep == 2 && !formThreeValidated) {
      return;
    }

    if(activeStep == 2) {
      setLoader(true);
      const data = {
        ...formOneFields,
        ...formTwoFields,
        ...formThreeFields
      }
      // Call the submission API
      submitSurveyForm(data).then((response) => {
        Swal.fire({
          icon: 'success',  
          title: t('Survey Created'),
          text: t('Survey Created Successfully'),
        });
        
        setLoader(false);

        props.history.push("/");
      }).catch((err) => {
        if(err) {
          var surveys = localStorage.getItem("surveys");
          if(!surveys) {
            localStorage.setItem("surveys", JSON.stringify([{...data}]));
          } else {
            const surveys_ = JSON.parse(surveys).push(data);
            localStorage.setItem("surveys", JSON.stringify(surveys_));
          }
          Swal.fire({
            icon: 'success',  
            title: 'Survey Created',  
            text: t('Survey Created Successfully') + ', ' +t('submit survey when you connect with internet'),
          });
        }
        setLoader(false);
        props.history.push("/");
      });
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const setFormFieldsFromFormOne = (field) => {
    setFormOneFields({
      ...formOneFields,
      ...field,
    });
  } 

  const setFormFieldsFromFormTwo = (field) => {
    setFormTwoFields({
      ...formTwoFields,
      ...field,
    });
  } 

  const setFormFieldsFromFormThree = (field) => {
    setFormThreeFields({
      ...formThreeFields,
      ...field,
    });
  } 


  if(userApproved) {
    return (
      <Container>
        <Box sx={{ width: '100%', marginTop: '55px' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                    labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                    );
                }
                if (isStepSkipped(index)) {
                    stepProps.completed = false;
                }
                return (
                    <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    {() => {
                        if(activeStep == 0) {
                            return <InterviewQuestion/>;
                        }
                    }}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {(() => {
                        if(activeStep == 0) {
                            return <InterviewQuestion 
                                      formData={formOneFields} 
                                      setFormFields={setFormFieldsFromFormOne} 
                                      formVaidatedFromOne={formVaidatedFromOne}/>;
                        } else if(activeStep == 1) {
                          return <Phq12 
                                    formData={formTwoFields} 
                                    formVaidatedFromTwo={formVaidatedFromTwo}
                                    setFormFields={setFormFieldsFromFormTwo} 
                                  />
                        }  else if(activeStep == 2) {
                          return (loader ? <RotatingLines width="100"/>  : <Preview  
                                                                              formValidatedFromThree={formValidatedFromThree} 
                                                                              setFormFields={setFormFieldsFromFormThree}
                                                                              formData={formThreeFields}  
                                                                              formOne={formOneFields} 
                                                                              formTwo={formTwoFields}/>)
                        } 
                    })()}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    >
                      {t('Back')}
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                        Skip
                    </Button>
                    )}

                    <Button onClick={handleNext} className='btn-primary'>
                      {activeStep === steps.length - 1 ? t('Finish') : t('Next')}
                    </Button>
                </Box>
                </React.Fragment>
            )}
        </Box>
      </Container>)
  } else {
    return (
    <div className='h-v100 d-flex'>
      <div className='m-auto' style={{display: 'inline-block'}}>
        <h3 className='m-auto'>{t("You've not been approved yet")}.</h3>
        <Link to="/"><h4>{t("Go Back")}</h4></Link>
      </div>
    </div>)
  }


}

export default withRouter(Survey);