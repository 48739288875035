import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class Sidebar extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
      const {t} = this.props;
        return (
            <div className="sidebar" data-color="white" data-active-color="danger">
              <div className="logo">
                <a href="#" className="simple-text logo-mini">
                  <div className="logo-image-small">
                    {/* <img src="../../assets/img/logo-small.png" /> */}
                  </div>
                  {/* <p>CT</p> */}
                </a>
                <a href="#" className="simple-text logo-normal">
                  {t('Dashboard')}
                  {/* <div className="logo-image-big">
                    <img src="../../assets/img/logo-big.png"/>
                  </div> */}
                </a>
              </div>
              <div className="sidebar-wrapper">
                <ul className="nav">
                  <li>
                    <Link to="/admin/list-users">
                      <i className="nc-icon nc-bank" />
                      <p>{t('Users Listing')}</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/survey-listing">
                      <i className="nc-icon nc-bank" />
                      <p>{t('Surveys Listing')}</p>
                    </Link>
                  </li>

                  <li>
                    <Link to="/admin/user/signup">
                      <i className="nc-icon nc-bank" />
                      <p>{t('User')} {t('Signup')}</p>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          );
    }
}

export default withTranslation()(Sidebar);