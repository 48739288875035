import React from 'react';
import { withTranslation } from 'react-i18next';
import {Formik} from 'formik';
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import SelectableButton from '../../../components/selectable-buttons';
import $ from 'jquery';
import { en } from '../../../i8n/en';

const schema = yup.object({
    headOfFamily: yup.string().required("** This field is required"), 
    age: yup.string().required("** This field is required"),
    gender: yup.string().required("** This field is required"),
    maritalStatus: yup.string().required("** This field is required"),
    education: yup.string().required("** This field is required"),
    isTheAnnualHouseholdIncomeSufficient: yup.string().required("** This field is required"), 
    apartFromFarmingDoYouHaveAnyOtherOccupation: yup.string().required("** This field is required"),
    doYouHaveAnyDebtLeft: yup.string().required("** This field is required"),
    whereDoYouGetLoansFrom: yup.string().required("** This field is required"),
    doesDebtOrAnyOtherCauseFamilyStrife: yup.string().required("** This field is required"),
    doYouFaceAnyKindOfMentalStressDueToYourChildrensMarriageOrTheirYoungAgeOrAnyOtherReasons: yup.string().required("** This field is required"),
    doYouOrAnyoneElseInTheHouseHasAnyKindOfAddiction: yup.string().required("** This field is required"),
    doesAnyoneInTheHouseHaveAMajorIllness: yup.string().required("** This field is required"),
    hasAnyoneCommittedSuicideAtHomeOrAmongRelatives: yup.string().required("** This field is required"),
    isAnyoneUnemployedAtYourHome: yup.string().required("** This field is required"),
    hasBarrennessNaturalDisastersOrOtherCausesCausedFinancialHardship: yup.string().required("** This field is required"),
    isTheCardOfAyushyamanBharatOrMahatmaJotibaPhuleJanArogyaYojanaissued: yup.string().required("** This field is required"),
});

class InterviewQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_submitted: false,
            errors: [],
            formData: {
                ...props.formData
            }
        }
    }

    submitInterviewQuestions = () => {
        const form = $("#interview-question-form").serializeArray();
        var data = {};
        $(form).each(function(index, obj){
            data[obj.name] = obj.value;
        });
        this.props.setFormFields(data);
    }

    handleSubmit = (e) => {
        e.preventDefault();
    }

    updateChange = (errors) => {
        if(errors && Object.keys(errors).length == 0) {
            this.props.formVaidatedFromOne(true);
            this.submitInterviewQuestions();
        } else { 
            this.props.formVaidatedFromOne(false);
        }
    }


    render() {
        const {is_submitted, errors, formData} = this.state;
        const t = this.props.t;
        return( 
            <Formik
                    enableReinitialize
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    initialValues={formData}
                    initialErrors={errors}
                    validateOnBlur={true}
                    validateOnChange={true}
                    validateOnMount={true}
                    
                >
                    {({ handleSubmit, handleChange, values, touched, errors, setFieldValue  }) => (
                        
                        <Form
                            id="interview-question-form"
                            onSubmit={handleSubmit}
                            onChange={(e) => this.updateChange(errors)}
                            autoComplete="off"
                        >
                            <div className='m-5'>
                                <div className='form-group'>
                                    <label>
                                        <b>{t('Name of the head of the family')}</b>
                                    </label>
                                    <input type='text' className='form-control' value={values.headOfFamily} onChange={handleChange} name="headOfFamily"/>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.headOfFamily} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='form-group'>
                                    <label>
                                        <b>{t(`Age`)}</b>
                                    </label>
                                    <input type='text' className='form-control' value={values.age} onChange={handleChange} name="age"/>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.age} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='form-group'>
                                    <label>
                                        <b>{t(`Gender`)}</b>
                                    </label>
                                    <select className='form-control' defaultValue={values.gender} name="gender" onChange={handleChange}>
                                        <option value=""></option>
                                        <option value={"Male"}>Male</option>
                                        <option value={"Female"}>Female</option>
                                        <option value={"Other"}>Other</option>
                                    </select>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.gender} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='form-group'>
                                    <label>
                                        <b>{t(`Marital Status`)}</b>
                                    </label>
                                    <select className='form-control' defaultValue={values.maritalStatus} name="maritalStatus" onChange={handleChange}>
                                        <option value=""></option>
                                        <option value={"Married"}>{t('Married')}</option>
                                        <option value={"Unmarried"}>{t('Unmarried')}</option>
                                        <option value={"Divorced"}>{t('Divorced')}</option>
                                        <option value={"Other"}>{t('Other')}</option>
                                    </select>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.maritalStatus} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='form-group'>
                                    <label>
                                        <b>{t(`Education`)}</b>
                                    </label>
                                    <select className='form-control' defaultValue={values.education} name="education" onChange={handleChange}>
                                        <option value=""></option>
                                        <option value={"Uneducated"}>{t('Uneducated')}</option>
                                        <option value={"Primary"}>{t('Primary')}</option>
                                        <option value={"Middle School"}>{t('Middle School')}</option>
                                        <option value={"High School"}>{t('High School')}</option>
                                        <option value={"Intermediate"}>{t('Intermediate')}</option>
                                        <option value={"Diploma"}>{t('Diploma')}</option>
                                        <option value={"Graduate"}>{t('Graduate')}</option>
                                        <option value={"Post Graduate"}>{t('Post Graduate')}</option>
                                        <option value={"Doctorate (PhD)"}>{t('Doctorate (PhD)')}</option>
                                        <option value={"Other"}>{t('Other')}</option>
                                    </select>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.education} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-4'>
                                            <b>{t(`Is the annual household income sufficient?`)}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            preview={true} 
                                            value={values.isTheAnnualHouseholdIncomeSufficient}
                                            name={"isTheAnnualHouseholdIncomeSufficient"} 
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.isTheAnnualHouseholdIncomeSufficient} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-4'>
                                            <b>{t(`Apart from farming, do you have any other occupation (goat, poultry or anything else)`)}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            preview={true} 
                                            value={values.apartFromFarmingDoYouHaveAnyOtherOccupation}
                                            name={"apartFromFarmingDoYouHaveAnyOtherOccupation"} 
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.apartFromFarmingDoYouHaveAnyOtherOccupation} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-4'>
                                            <b>{t(`Do you have any debt left ?`)}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            preview={true} 
                                            value={values.doYouHaveAnyDebtLeft}
                                            name={"doYouHaveAnyDebtLeft"} 
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.doYouHaveAnyDebtLeft} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='form-group'>
                                    <label>
                                        <b>{t(`Where do you get loans from`)}</b>
                                    </label>
                                    <input type='text' className='form-control' value={values.whereDoYouGetLoansFrom} onChange={handleChange} name="whereDoYouGetLoansFrom"/>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.whereDoYouGetLoansFrom} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label>
                                            <b>{t(`Does debt or any other cause cause family strife?`)}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            preview={true} 
                                            value={values.doesDebtOrAnyOtherCauseFamilyStrife}
                                            name={"doesDebtOrAnyOtherCauseFamilyStrife"} 
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.doesDebtOrAnyOtherCauseFamilyStrife} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-4'>
                                            <b>{t(`Do you face any kind of mental stress due to your children's marriage or their young age or any other reasons?`)}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            preview={true} 
                                            value={values.doYouFaceAnyKindOfMentalStressDueToYourChildrensMarriageOrTheirYoungAgeOrAnyOtherReasons}
                                            name={"doYouFaceAnyKindOfMentalStressDueToYourChildrensMarriageOrTheirYoungAgeOrAnyOtherReasons"} 
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.doYouFaceAnyKindOfMentalStressDueToYourChildrensMarriageOrTheirYoungAgeOrAnyOtherReasons} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-4'>
                                            <b>{t(`Do you or anyone else in the house has any kind of addiction?`)}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            preview={true} 
                                            value={values.doYouOrAnyoneElseInTheHouseHasAnyKindOfAddiction}
                                            name={"doYouOrAnyoneElseInTheHouseHasAnyKindOfAddiction"} 
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.doYouOrAnyoneElseInTheHouseHasAnyKindOfAddiction} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-4'>
                                            <b>{t(`Does anyone in the house have a major illness?`)}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            preview={true} 
                                            value={values.doesAnyoneInTheHouseHaveAMajorIllness}
                                            name={"doesAnyoneInTheHouseHaveAMajorIllness"} 
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.doesAnyoneInTheHouseHaveAMajorIllness} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-4'>
                                            <b>{t(`Has anyone committed suicide at home or among relatives? Or attempted suicide?`)}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            preview={true} 
                                            value={values.hasAnyoneCommittedSuicideAtHomeOrAmongRelatives}
                                            name={"hasAnyoneCommittedSuicideAtHomeOrAmongRelatives"} 
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.hasAnyoneCommittedSuicideAtHomeOrAmongRelatives} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-4'>
                                            <b>{t(`Is anyone unemployed at your home?`)}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            preview={true} 
                                            value={values.isAnyoneUnemployedAtYourHome}
                                            name={"isAnyoneUnemployedAtYourHome"} 
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.isAnyoneUnemployedAtYourHome} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-4'>
                                            <b>{t(`Has barrenness, natural disasters or other causes caused financial hardship?`)}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            preview={true} 
                                            value={values.hasBarrennessNaturalDisastersOrOtherCausesCausedFinancialHardship}
                                            name={"hasBarrennessNaturalDisastersOrOtherCausesCausedFinancialHardship"} 
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.hasBarrennessNaturalDisastersOrOtherCausesCausedFinancialHardship} </Form.Control.Feedback>
                                </div>
                                <hr/>
                                <div className='row'>
                                    <div className="col-7">
                                        <label className='mr-4'>
                                            <b>{t(`Is the card of Ayushyaman Bharat or Mahatma Jotiba Phule Jan Arogya Yojana issued? And does it benefit the sick?`)}</b>
                                        </label>
                                    </div>
                                    <div className="col-5">
                                        <SelectableButton
                                            preview={true} 
                                            value={values.isTheCardOfAyushyamanBharatOrMahatmaJotibaPhuleJanArogyaYojanaissued}
                                            name={"isTheCardOfAyushyamanBharatOrMahatmaJotibaPhuleJanArogyaYojanaissued"} 
                                            onChange={setFieldValue} 
                                            errors={errors} 
                                            updateChange={this.updateChange}/>
                                    </div>
                                    <Form.Control.Feedback type="invalid" className="d-block text-capitalize"> {errors.isTheCardOfAyushyamanBharatOrMahatmaJotibaPhuleJanArogyaYojanaissued} </Form.Control.Feedback>
                                </div>
                            </div> 
                        </Form>
                    )}

                </Formik>
            
        );
    }
}

export default  withTranslation()(InterviewQuestion);