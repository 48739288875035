import React from "react";

import {
    Button,
    Card,
    Form,
    Row,
    Col,
} from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import  { adminLogin } from "../../api";
import {RotatingLines} from 'react-loader-spinner';
import { withTranslation } from 'react-i18next';

class AdminLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            contactNumber: "",
            password: "",
            errors: {
                errMessage: "",
                contactNumber: "",
                password: "",
            }
        }
    }

    validateForm = () => {
        const {t} = this.props;
        const contactNumber = this.state.contactNumber;
        const password = this.state.password;
        
        let valid = true;
        if(contactNumber == "") {
            this.setState({errors : {...this.state.errors, contactNumber: t("Please enter contact number")}});
            valid = false;
        } else if(contactNumber.length != 10) {
            this.setState({errors : {...this.state.errors, contactNumber: t("Please enter valid contact number")}});
            valid = false;
        }

        if(password == "") {
            valid = false;
            this.setState({errors : {...this.state.errors, password: t("Please enter password")}});
        }

        if(valid) {
            this.setState({
                errors: {
                    errMessage: "",
                    contactNumber: "",
                    password: "",
                }
            })
        }
        return valid;
    }

    onFormSubmitHandler = (e) => {
        e.preventDefault();
        if(this.validateForm()) {
            // Start the loader
            this.setState({loader: true});

            // try to login
            adminLogin({
                ...this.state
            }).then((res) => {
                localStorage.setItem("accessToken", res.accessToken);
                localStorage.setItem("role", "admin");
                this.props.history.push("/admin");
            }).catch((err) => {
                console.log("err", err.data);
                if(err?.data?.message) {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            errMessage: err?.data?.message
                        }
                    });
                }
            }).finally(() => {
                this.setState({loader: false});
            });
        }
    }

    changeanguageHandler = (lang) => {
        const {i18n} = this.props;
        i18n.changeLanguage(lang);
        localStorage.setItem("lang", lang);
    }

    render() {
        const {t} = this.props;
        return (
            <div className="h-v100 d-flex">
                <Col md="6" className="m-auto p-4">
                    <Card className="p-4">
                    <Card.Header>
                        <Card.Title as="h4">{t('Login Page')} (Admin)</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {this.state.loader ? <RotatingLines width="100"/> :
                        <Form onSubmit={this.onFormSubmitHandler} autoComplete="off">
                            {this.state.errors.errMessage ? <small className="text-danger">{this.state.errors.errMessage}</small>: null}
                            <Row>
                                <Col className="pl-1 pr-1" md="12">
                                <Form.Group>
                                    <label htmlFor="exampleInputEmail1">
                                        {t('Phone Number')}
                                    </label>
                                    <Form.Control
                                        autoComplete={"off"}
                                        placeholder={t("Phone Number")}
                                        type="number"
                                        min="10"
                                        name="contactNumber"
                                        value={this.state.contactNumber}
                                        onChange={(e) => this.setState({contactNumber: e.target.value})}
                                    ></Form.Control>
                                    {this.state.errors.contactNumber ? <small className="text-danger">{this.state.errors.contactNumber}</small>: null}
                                </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pl-1 pr-1" md="12">
                                <Form.Group>
                                    <label>{t("Password")}</label>
                                    <Form.Control
                                        autoComplete={"off"}
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={(e) => this.setState({password: e.target.value})}
                                    ></Form.Control>
                                    {this.state.errors.password ? <small className="text-danger">{this.state.errors.password}</small>: null}
                                </Form.Group>
                                </Col>
                            </Row>
                            <Button
                                className="btn-outline-info mt-2"
                                type="submit"
                                variant="default"
                            >
                                {t("Login")}
                            </Button>
                            <div className="clearfix"></div>
                            <div className="mt-5">
                                <div className="row">
                                    <label>Language : </label>
                                    <select defaultValue={this.props.i18n.language} className="form-control" onChange={(e) => this.changeanguageHandler(e.target.value) }>
                                        <option value={"ma"}>Marathi</option>
                                        <option value={"en"}>English</option>
                                    </select>
                                </div>
                            </div>
                        </Form>}
                    </Card.Body>
                    </Card>
                </Col>
            </div>
        )
    }
}

export default withTranslation()(withRouter(AdminLogin));