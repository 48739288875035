import React from 'react';
import {withRouter} from 'react-router-dom';

class AdminNavbar extends React.Component {
    constructor(props){
      super(props);
    }

    onLogoutButtonClickHandler = (e) => {
      e.preventDefault();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("role");
      this.props.history.push("/login");
    }

    render() {
        return (
          <nav className="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
            <div className="container-fluid">
              <div className="navbar-wrapper">
                <div className="navbar-toggle">
                  <button type="button" className="navbar-toggler">
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </button>
                </div>
                <a className="navbar-brand" href="javascript:;">Dashboard</a>
              </div>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
              </button>
              <div className="collapse navbar-collapse justify-content-end" id="navigation">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link btn-rotate" onClick={this.onLogoutButtonClickHandler}>
                      <i className="nc-icon nc-settings-gear-65" />
                      <p>
                        <span className="d-lg-none d-md-block">Account</span>
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        );
      }
}

export default withRouter(AdminNavbar);
