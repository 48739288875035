import React from 'react';
import AdminNavbar from './layout/Navbar';
import Sidebar from "./layout/Sidebar";
import Swal from "sweetalert2";  
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/paper-dashboard.min.css";
import "../../assets/demo/demo.css";
import { submitSurveyForm } from '../../api';
import { withTranslation } from 'react-i18next';
class UserDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listing: [],
        }
    }

    componentDidMount() {
        const items = localStorage.getItem("surveys");
        if(items) {
            this.setState({listing: JSON.parse(items)});
        }
    }

    submitSurveysFromLocalStorage = () => {
        const surveys = this.state.listing;
        surveys.forEach(async (survey, index) => {
            try {
                const submit = await submitSurveyForm(survey);
                if(submit) {
                    const leftSurveys = surveys.filter((val, ind) => ind !== index);
                    this.setState({listing: leftSurveys});
                }
            } catch(e) {
                Swal.fire({
                    icon: 'warning',  
                    title: 'Survey Unsent !',  
                    text: 'Please try with internet again.',
                });
            }
        });

    }
    render() {
        const {t} = this.props;
        return (
            <>
                <div className="wrapper ">
                    <Sidebar/>
                    <div className="main-panel">
                        <AdminNavbar/>
                        <div className="content">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className='col'>
                                                <h4 className="card-title pull-left"> {t('Surveys')} ({t('Unsent')}) </h4>
                                            </div>
                                            <div className='col'>
                                                <button className='btn btn-primary pull-right' onClick={this.submitSurveysFromLocalStorage}>
                                                    {t('Submit Unsent')}
                                                </button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>
                                                            {t('S. No.')}
                                                        </th>
                                                        <th>
                                                            {t('Head Of Family')}
                                                        </th>
                                                        <th>
                                                            {t('Age')}
                                                        </th>
                                                        <th>
                                                            {t('Gender')}
                                                        </th>
                                                        <th>
                                                            {t('Marital Status')}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(this.state.listing) && this.state.listing.map((list, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td>{index+1}</td>
                                                                <td>{list.headOfFamily}</td>
                                                                <td>{list.age}</td>
                                                                <td>{list.gender}</td>
                                                                <td>{list.maritalStatus}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default  withTranslation()(UserDashboard);